import { default as change_45emailWUnw2wb5IfMeta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/change-email.vue?macro=true";
import { default as change_45password5pwbPYCe6XMeta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/change-password.vue?macro=true";
import { default as _91key_93agEHZVwK7WMeta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/confirm-email/[key].vue?macro=true";
import { default as loginnaFITGDCxdMeta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/login.vue?macro=true";
import { default as logoutyNBVGMbCr0Meta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/logout.vue?macro=true";
import { default as cardsyAFUGDaIFyMeta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/profile/cards.vue?macro=true";
import { default as edit61CKJVfWrpMeta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/profile/edit.vue?macro=true";
import { default as index8PUwSNn8H2Meta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/profile/index.vue?macro=true";
import { default as _91key_93MrKV2U8QqUMeta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/reset-password/[key].vue?macro=true";
import { default as signupWdIMhUxDssMeta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/signup.vue?macro=true";
import { default as _91token_93UxcC0FUBM7Meta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/bnpl_invite/[locationNumber]/[token].vue?macro=true";
import { default as index5Un3kR1wRFMeta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/index.vue?macro=true";
import { default as searchb5M0Xm89bLMeta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/office/search.vue?macro=true";
import { default as historyvqYKe5OZVUMeta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/purchase/history.vue?macro=true";
import { default as item3YGr8AAlPBMeta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/purchase/item.vue?macro=true";
import { default as location0UzeCFPJD6Meta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/purchase/location.vue?macro=true";
import { default as receiptwJimdHaC3RMeta } from "/home/runner/work/l-box-user-front/l-box-user-front/pages/purchase/receipt.vue?macro=true";
export default [
  {
    name: "accounts-change-email",
    path: "/accounts/change-email",
    meta: change_45emailWUnw2wb5IfMeta || {},
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/change-email.vue").then(m => m.default || m)
  },
  {
    name: "accounts-change-password",
    path: "/accounts/change-password",
    meta: change_45password5pwbPYCe6XMeta || {},
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/change-password.vue").then(m => m.default || m)
  },
  {
    name: "accounts-confirm-email-key",
    path: "/accounts/confirm-email/:key()",
    meta: _91key_93agEHZVwK7WMeta || {},
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/confirm-email/[key].vue").then(m => m.default || m)
  },
  {
    name: "accounts-login",
    path: "/accounts/login",
    meta: loginnaFITGDCxdMeta || {},
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/login.vue").then(m => m.default || m)
  },
  {
    name: "accounts-logout",
    path: "/accounts/logout",
    meta: logoutyNBVGMbCr0Meta || {},
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/logout.vue").then(m => m.default || m)
  },
  {
    name: "accounts-profile-cards",
    path: "/accounts/profile/cards",
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/profile/cards.vue").then(m => m.default || m)
  },
  {
    name: "accounts-profile-edit",
    path: "/accounts/profile/edit",
    meta: edit61CKJVfWrpMeta || {},
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/profile/edit.vue").then(m => m.default || m)
  },
  {
    name: "accounts-profile",
    path: "/accounts/profile",
    meta: index8PUwSNn8H2Meta || {},
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-reset-password-key",
    path: "/accounts/reset-password/:key()",
    meta: _91key_93MrKV2U8QqUMeta || {},
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/reset-password/[key].vue").then(m => m.default || m)
  },
  {
    name: "accounts-signup",
    path: "/accounts/signup",
    meta: signupWdIMhUxDssMeta || {},
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/accounts/signup.vue").then(m => m.default || m)
  },
  {
    name: "bnpl_invite-locationNumber-token",
    path: "/bnpl_invite/:locationNumber()/:token()",
    meta: _91token_93UxcC0FUBM7Meta || {},
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/bnpl_invite/[locationNumber]/[token].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index5Un3kR1wRFMeta || {},
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "office-search",
    path: "/office/search",
    meta: searchb5M0Xm89bLMeta || {},
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/office/search.vue").then(m => m.default || m)
  },
  {
    name: "purchase-history",
    path: "/purchase/history",
    meta: historyvqYKe5OZVUMeta || {},
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/purchase/history.vue").then(m => m.default || m)
  },
  {
    name: "purchase-item",
    path: "/purchase/item",
    meta: item3YGr8AAlPBMeta || {},
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/purchase/item.vue").then(m => m.default || m)
  },
  {
    name: "purchase-location",
    path: "/purchase/location",
    meta: location0UzeCFPJD6Meta || {},
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/purchase/location.vue").then(m => m.default || m)
  },
  {
    name: "purchase-receipt",
    path: "/purchase/receipt",
    component: () => import("/home/runner/work/l-box-user-front/l-box-user-front/pages/purchase/receipt.vue").then(m => m.default || m)
  }
]