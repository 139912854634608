import revive_payload_client_65P792W9yP from "/home/runner/work/l-box-user-front/l-box-user-front/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_ce37o5clbaqqaats4uzo2iueki/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_10kWg09RJC from "/home/runner/work/l-box-user-front/l-box-user-front/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_ce37o5clbaqqaats4uzo2iueki/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xulfepxd1l from "/home/runner/work/l-box-user-front/l-box-user-front/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_ce37o5clbaqqaats4uzo2iueki/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_zg6IsqzTpM from "/home/runner/work/l-box-user-front/l-box-user-front/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_ce37o5clbaqqaats4uzo2iueki/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_nIcGY0oO0Y from "/home/runner/work/l-box-user-front/l-box-user-front/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_ce37o5clbaqqaats4uzo2iueki/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_m9BsAfYUKJ from "/home/runner/work/l-box-user-front/l-box-user-front/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.0_typescript@5.5.2_vue@3.4.31_typescript@5.5.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/l-box-user-front/l-box-user-front/.nuxt/components.plugin.mjs";
import prefetch_client_LDg7MznqeB from "/home/runner/work/l-box-user-front/l-box-user-front/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_ce37o5clbaqqaats4uzo2iueki/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_wQWxobnKVP from "/home/runner/work/l-box-user-front/l-box-user-front/node_modules/.pnpm/nuxt-gtag@2.0.6_magicast@0.3.4_rollup@4.18.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_mWlY4dt194 from "/home/runner/work/l-box-user-front/l-box-user-front/node_modules/.pnpm/nuxt3-notifications@1.2.2_@nuxt+kit@3.12.2_magicast@0.3.4_rollup@4.18.0__vue@3.4.31_typescript@5.5.2_/node_modules/nuxt3-notifications/dist/runtime/plugin.mjs";
import http_plugin_nuxt_Bw1l5DVU9p from "/home/runner/work/l-box-user-front/l-box-user-front/node_modules/.pnpm/@nuxt-alt+http@1.7.10_magicast@0.3.4_nuxi@3.12.0_rollup@4.18.0_typescript@5.5.2_vue-tsc@2.0.22_typescript@5.5.2_/node_modules/@nuxt-alt/http/dist/runtime/http-plugin.nuxt.mjs";
import auth_plugin_6DDHMgc34l from "/home/runner/work/l-box-user-front/l-box-user-front/.nuxt/auth.plugin.mjs";
import chunk_reload_client_W1YXTiFdxg from "/home/runner/work/l-box-user-front/l-box-user-front/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_ce37o5clbaqqaats4uzo2iueki/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_DdV5SuhNPn from "/home/runner/work/l-box-user-front/l-box-user-front/auth-plugins/auth.ts";
import vue3_particles_client_SPQVMPPaF2 from "/home/runner/work/l-box-user-front/l-box-user-front/plugins/vue3-particles.client.ts";
import sentry_3AyO8nEfhE from "/home/runner/work/l-box-user-front/l-box-user-front/plugins/sentry.ts";
export default [
  revive_payload_client_65P792W9yP,
  unhead_10kWg09RJC,
  router_xulfepxd1l,
  payload_client_zg6IsqzTpM,
  check_outdated_build_client_nIcGY0oO0Y,
  plugin_vue3_m9BsAfYUKJ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_LDg7MznqeB,
  plugin_client_wQWxobnKVP,
  plugin_mWlY4dt194,
  http_plugin_nuxt_Bw1l5DVU9p,
  auth_plugin_6DDHMgc34l,
  chunk_reload_client_W1YXTiFdxg,
  auth_DdV5SuhNPn,
  vue3_particles_client_SPQVMPPaF2,
  sentry_3AyO8nEfhE
]