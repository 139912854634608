import validate from "/home/runner/work/l-box-user-front/l-box-user-front/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_ce37o5clbaqqaats4uzo2iueki/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/home/runner/work/l-box-user-front/l-box-user-front/middleware/redirect.global.ts";
import auth from "/home/runner/work/l-box-user-front/l-box-user-front/node_modules/.pnpm/@nuxt-alt+auth@3.1.7_magicast@0.3.4_nuxi@3.12.0_rollup@4.18.0_typescript@5.5.2_vue-tsc@2.0.22_ca4ybze4inyye7cvcgrozkrgei/node_modules/@nuxt-alt/auth/dist/runtime/core/middleware.mjs";
import manifest_45route_45rule from "/home/runner/work/l-box-user-front/l-box-user-front/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_ce37o5clbaqqaats4uzo2iueki/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  auth,
  manifest_45route_45rule
]
export const namedMiddleware = {}