<template>
  <div>
    <NuxtLoadingIndicator />
    <NuxtNotifications class="pr-4 pt-4" position="top right" :speed="500" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <ClientOnly>
      <AppReload />
    </ClientOnly>
  </div>
</template>
