<template>
  <div
    v-if="existNewerVersion"
    class="fixed inset-0 flex items-center justify-center bg-white/90"
  >
    <div class="rounded-lg bg-white p-4 shadow-lg">
      <p class="text-lg font-semibold">新しいバージョンが利用可能です</p>
      <p class="text-sm text-gray-600">
        利用を続けるためにはページを再読み込みしてください
      </p>
      <button
        class="mt-4 rounded-lg bg-blue-500 px-4 py-2 text-white"
        @click="() => refresh()"
      >
        再読み込み
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
const nuxtApp = useNuxtApp();
const existNewerVersion = ref(false);

const foundNewerVersion = () => {
  existNewerVersion.value = true;
};

const refresh = () => {
  location.reload();
};

onBeforeMount(() => {
  nuxtApp.hook('app:manifest:update', foundNewerVersion);
});

onUnmounted(() => {
  // @ts-ignore
  nuxtApp.removeHook('app:manifest:update', foundNewerVersion);
});
</script>
