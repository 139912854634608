import * as Sentry from '@sentry/vue';
import { parseHost } from 'ufo';

declare global {
  interface Window {
    debugSentryConfig(): void;
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const config = useRuntimeConfig();

  // BASE_URLからホスト名を取得
  const { hostname } = parseHost(config.public.BASE_URL);

  // Sentryの設定
  const sentryConfig = {
    debug: config.public.sentryEnableDebug || false, // Enable debug mode
    environment: config.public.environment || 'local', // Set environment
    // Performance Monitoring
    tracesSampleRate: Number(config.public.sentryTracesSampleRate) || 0.5,
    // Profiling
    profilesSampleRate: Number(config.public.sentryProfilesSampleRate) || 0.5,
    // Session Replay
    replaysSessionSampleRate:
      Number(config.public.sentryReplaysSessionSampleRate) || 0.1,
    replaysOnErrorSampleRate:
      Number(config.public.sentryReplaysOnErrorSampleRate) || 1.0,
  };

  // Sentryの設定をデバッグ用にグローバル関数にセット
  window.debugSentryConfig = () => {
    // eslint-disable-next-line no-console
    console.log('Sentry Config:', sentryConfig);
  };

  // Sentryの初期化
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.sentryDSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    debug: sentryConfig.debug, // Enable debug mode
    environment: sentryConfig.environment, // Set environment
    // Performance Monitoring
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: sentryConfig.tracesSampleRate, // Capture 100% of the transactions, reduce in production!
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^\//, hostname],
    // Profiling
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: sentryConfig.profilesSampleRate,
    // Session Replay
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: sentryConfig.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: sentryConfig.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
});
