export default defineNuxtRouteMiddleware((to) => {
  if (
    to.path === '/.well-known/change-password' ||
    to.path === '/.well-known/change-password/'
  ) {
    return navigateTo({
      name: 'accounts-change-password',
    });
  }
});
