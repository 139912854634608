import { setUser } from '@sentry/vue';

import type { NuxtApp } from '#app';

const setUserToSentry = ($auth: NuxtApp['$auth']) => {
  if (!$auth.loggedIn || !$auth.user) {
    setUser(null);
    return;
  }

  setUser({
    id: $auth.user.pk,
  });
};

export default defineNuxtPlugin(() => {
  const $auth = useAuth();

  $auth.$storage.watchState('loggedIn', () => {
    // ログイン状態が変更されたときに呼び出される
    setUserToSentry($auth);
  });

  // 初期のログイン状態をセット
  setUserToSentry($auth);
});
